/* eslint-disable react/jsx-key */
/* eslint-disable max-len */
import React, { useState } from 'react';
import styled from 'styled-components';
import { graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
// import { AnchorLink } from 'gatsby-plugin-anchor-links';
import missionMobile1 from 'assets/images/home/mission-mobile-1.png';
import missionMobile2 from 'assets/images/home/mission-mobile-2.png';
import missionMobile3 from 'assets/images/home/mission-mobile-3.png';
import missionMobile4 from 'assets/images/home/mission-mobile-4.png';
import missionDesktop from 'assets/images/home/mission-desktop.png';
import heroMobileBg from 'assets/images/home/hero/mobile-bg.svg';
import heroDesktopBg from 'assets/images/home/hero/desktop-bg.svg';
import dogs from 'assets/images/common/cat-and-dog.svg';
import { HeaderComponent } from 'components/Header/HeaderEn';
import { useForm, ValidationError } from '@formspree/react';
import { LOCALES } from 'intl/locales';
import { messages } from 'intl/messages';

const language = LOCALES.ENGLISH

function ContactForm({ onSubmitSuccess }) {

  const [state, handleSubmit] = useForm('mjvqkqgr');
  if (state.succeeded) {
    onSubmitSuccess();
    return (
      <div className="msg-success">
        <img src="../../heart.png" width="128" height="109" alt="heart" />
        <p className="msg-success__subtitle">{messages[language].formConfirmation1}</p>
        <p className="msg-success__title">{messages[language].formConfirmation2}</p>
        <p className="msg-success__desc">{messages[language].formConfirmation3}</p>
      </div>
    );
  }
  return (
    <form onSubmit={handleSubmit}>
      <fieldset>
        <div className="field">
          <label htmlFor="ic-name">
            {messages[language].formYourName}
          </label>
          <input type="text" id="ic-name" name="ic-name" required />
          <ValidationError
            prefix="Name"
            field="ic-name"
            errors={state.errors}
          />
        </div>

        <div className="field">
          <label htmlFor="ic-email">
            {messages[language].formEmail}
          </label>
          <input type="email" id="ic-email" name="ic-email" required />
          <ValidationError
            prefix="Email"
            field="ic-email"
            errors={state.errors}
          />
        </div>

        {/* {state.errors && state.errors.length ? state.errors.map(((e) => <p style={{ color: '#C71212' }}>{(e.code === 'EMPTY') && 'Uzupełnij pola przed wysłaniem wiadomości'}</p>
                )) : null } */}

        <p className="agree">
          <input type="checkbox" id="ic-agree" name="ic-agree" required />
          {messages[language].formConsent1}
          {' '}
          <a href="/polityka-prywatnosci/" target="_blank">
            {messages[language].formConsent2}
          </a>
          {' '}
          {messages[language].formConsent3}
        </p>

        <button className="btn">
          {messages[language].formButtonSubmit}
        </button>
      </fieldset>
    </form>
  );
}

function ContactFormBusiness({ onBusinessSubmitSuccess }) {

  const [state, handleBusinessSubmit] = useForm('mqkvavey');
  if (state.succeeded) {
    onBusinessSubmitSuccess();
    return (
      <div className="msg-success">
        <img src="../../heart.png" width="128" height="109" alt="heart" />
        <p className="msg-success__subtitle">{messages[language].formConfirmation1}</p>
        <p className="msg-success__title">{messages[language].formConfirmation2}</p>
        <p className="msg-success__desc">{messages[language].formConfirmation3}</p>
      </div>
    );
  }
  return (
    <form onSubmit={handleBusinessSubmit}>
      <fieldset>
        <div className="field">
          <label htmlFor="bc-company-name">
            {messages[language].formCompany}
          </label>
          <input type="text" id="bc-company-name" name="bc-company-name" required />
          <ValidationError
            prefix="Company"
            field="bc-company-name"
            errors={state.errors}
          />
        </div>

        <div className="field">
          <label htmlFor="no-name">
            {messages[language].formCompanyType}
          </label>
          <ul>
            <li>
              <label>
                <input type="radio" id="bc-activity-distributor" name="bc-activity" value="distributor" />
                {messages[language].formCompanyTypeDistributor}
              </label>
            </li>
            <li>
              <label>
                <input type="radio" id="bc-activity-beauty-studio" name="bc-activity" value="beauty-studio" />
                {messages[language].formCompanyTypeBeauty}
              </label>
            </li>
            <li>
              <label>
                <input type="radio" id="bc-activity-breeder" name="bc-activity" value="breeder" />
                {messages[language].formCompanyTypeBreeder}
              </label>
            </li>
            <li>
              <label>
                <input type="radio" id="bc-activity-chain-of-stores" name="bc-activity" value="chain-of-stores" />
                {messages[language].formCompanyTypeChain}
              </label>
            </li>
            <li>
              <label>
                <input type="radio" id="bc-activity-wholesaler" name="bc-activity" value="wholesaler" />
                {messages[language].formCompanyTypeWholesale}
              </label>
            </li>
            <li>
              <label>
                <input type="radio" id="bc-activity-shop" name="bc-activity" value="shop" />
                {messages[language].formCompanyTypeStore}
              </label>
            </li>
          </ul>
          <ValidationError
            prefix="Company"
            field="bc-company-name"
            errors={state.errors}
          />
        </div>

        <div className="field">
          <label htmlFor="bc-email">
            {messages[language].formEmail}
          </label>
          <input type="email" id="bc-email" name="bc-email" required />
          <ValidationError
            prefix="Email"
            field="bc-email"
            errors={state.errors}
          />
        </div>

        <div className="field">
          <label htmlFor="bc-phone">
            {messages[language].formPhoneNumber}
          </label>
          <input type="text" id="bc-phone" name="bc-phone" required />
          <ValidationError
            prefix="Phone"
            field="bc-phone"
            errors={state.errors}
          />
        </div>

        <div className="field">
          <label htmlFor="bc-job-position">
            {messages[language].formPosition}
          </label>
          <input type="text" id="bc-job-position" name="bc-job-position" required />
          <ValidationError
            prefix="Job"
            field="bc-job-position"
            errors={state.errors}
          />
        </div>

        {/* {state.errors.length ? state.errors.map(((e) => <p style={{ color: '#C71212' }}>{(e.code === 'EMPTY') && 'Uzupełnij pola przed wysłaniem wiadomości'}</p>
        )) : null } */}

        <p className="agree">
          <input type="checkbox" id="ic-agree" name="ic-agree" required />
          {messages[language].formConsent1}
          {' '}
          <a href="/polityka-prywatnosci/" target="_blank">
            {messages[language].formConsent2}
          </a>
          {' '}
          {messages[language].formConsent3}
        </p>

        <button className="btn">
          {messages[language].formButtonSubmit}
        </button>
      </fieldset>
    </form>
  );
}

const Main = styled.main`
  margin-bottom: 0;

  .heading {
    margin: 0 0 42px;
    text-align: center;

    @media only screen and (min-width: 768px) {
      margin: 0 0 64px;
    }

    &__desc {
      font-size: 1.8rem;
      margin: 0 auto;
      max-width: 500px;

      @media only screen and (min-width: 768px) {
        font-size: 2.2rem;
      }
    }
  }
`;

const Hero = styled.section`
  background: #f0f0f0 url(${heroMobileBg}) no-repeat 50% 50%/cover;
  margin: -70px 0 0;

  @media only screen and (min-width: 480px) {
    background-image: url(${heroDesktopBg});
    margin: -25px 0 0;
  }

  @media only screen and (min-width: 768px) {
    margin: -85px 0 0;
  }

  .gatsby-image-wrapper {
    width: 100%;
  }
`;

const Mission = styled.section`
  background: #78569D;
  color: #FEFAF5;
  margin: 0;
  position: relative;

  h2 {
    margin: 0 0px 20px;
  }

  @media only screen and (max-width: 1199px) {
    padding: 183px 0;

    &::before {
      background: url(${missionMobile2}) no-repeat 0 0;
      content: '';
      height: 155px;
      width: 119px;
      right: 24px;
      position: absolute;
      top: 41px;
    }

    &::after {
      background: url(${missionMobile4}) no-repeat 0 0;
      content: '';
      height: 81px;
      width: 81px;
      right: 27px;
      position: absolute;
      bottom: 127px;
    }

    h2 {
      position: relative;

      &::before {
        background: url(${missionMobile1}) no-repeat 0 0;
        content: '';
        height: 64px;
        width: 64px;
        left: 0;
        position: absolute;
        top: -86px;
      }
    }

    p:not([class]) {
      position: relative;

      &::before {
        background: url(${missionMobile3}) no-repeat 0 0;
        content: '';
        height: 164px;
        width: 109px;
        left: 0;
        position: absolute;
        top: calc(100% + 24px);
      }
    }
  }

  @media only screen and (min-width: 1200px) {
    background: #78569D url(${missionDesktop}) no-repeat 50% calc(50% - 30px);
    padding: 173px 0 183px;

    h2 {
      margin: 0 0 34px;
    }
  }

  .row {
    margin: 0 auto;
    max-width: 870px;
  }

  .lead {
    font-size: 2.9rem;
    font-weight: 300;
    letter-spacing: -0.14rem;
    line-height: 109%;
    margin: 0 0 16px;

    @media only screen and (min-width: 1400px) {
      font-size: 4.5rem;
      line-height: 104%;
      margin: 0 0 28px;
    }
  }

  p:not([class]) {
    font-size: 1.8rem;
    max-width: 480px;

    @media only screen and (min-width: 1400px) {
      font-size: 2.2rem;
      line-height: 130%;
    }
  }
`;

const Products = styled.section`
  padding: 74px 0 60px;

  @media only screen and (min-width: 1024px) {
    padding: 91px 0;
  }

  h2 {
    margin: 0 0 8px;
    text-align: center;

    @media only screen and (min-width: 1024px) {
      margin: 0 0 42px;
    }
  }

  @media only screen and (max-width: 767px) {
    .columns {
      .gatsby-image-wrapper {
        margin: 0 0 24px;
        max-width: 350px;
      }
    }
  }

  .only-mobile {
    width: 100%;
  }

  .ingredients {
    margin-top: 50px;

    @media only screen and (min-width: 1024px) {
      margin-top: 126px;
    }
  }

  .list-check {
    list-style: none;
    margin: 0 0 32px;
    padding: 0;

    li {
      margin: 0 0 12px;
      padding: 0 0 0 35px;
      position: relative;

      svg {
        left: 0;
        position: absolute;
        top: 0;
      }
    }
  }
`;

const AboutUs = styled.section`
  .about-us__desc {
    padding: 24px;
  }

  @media only screen and (min-width: 800px) {
    align-items: center;
    display: flex;
    flex-wrap: wrap;

    .about-us__desc,
    .about-us__image {
      width: 50%;
    }

    .about-us__image {
      text-align: right;
    }

    .about-us__desc {
      order: 1;

      @media only screen and (min-width: 1024px) {
        padding: 50px;
      }

      @media only screen and (min-width: 1400px) {
        padding: 136px;
      }
    }

    .about-us__content {
      @media only screen and (min-width: 1200px) {
        max-width: 406px;
      }
    }
  }

  .list-experience {
    display: grid;
    grid-gap: 8px;
    grid-template-columns: repeat(2, 1fr);
    list-style: none;
    margin: 0 0 20px;
    padding: 0;

    img {
      border-radius: 128px;
      width: 100%;
    }

    .gatsby-image-wrapper {
      width: 100%;
    }

    p {
      line-height: 100%;
      margin: 0;
    }

    .outline {
      border: 1px solid #242323;
      border-radius: 128px;
      height: 100%;
      display: flex;
      align-items: center;
      font-size: 1.2rem;
      padding: 13px;

      @media only screen and (min-width: 1200px) {
        font-size: 1.4rem;
        padding: 13px 20px 13px 25px;
      }
    }
  }

  @media only screen and (min-width: 1024px) {
      .list-experience {
        margin: 0 0 48px;
      }

      .outline {
        font-size: 1.4rem;
        padding: 13px 35px;
      }
    }
`;

const Benefits = styled.section`
  margin: 60px 0;

  @media only screen and (min-width: 1024px) {
    margin: 125px 0;
  }

  .title {
    font-size: 2rem;
    font-weight: 500;
    line-height: 130%;
    margin: 0 0 5px;

    @media only screen and (min-width: 1024px) {
      margin: 0 0 10px;
    }
  }

  ul.list-benefits {
    display: grid;
    grid-gap: 20px;
    list-style: none;
    padding: 0;

    @media only screen and (min-width: 1024px) {
      grid-gap: 40px;
      grid-template-columns: repeat(2, 1fr);

      svg {
        left: 0;
        position: absolute;
        top: 0;
      }

      .list-benefits__desc {
        padding: 0 0 0 50px;
        position: relative;
      }
    }
  }

  svg {
    margin: 0 0 10px;
  }
`;

const Contact = styled.section`
  background: #344f46;
  color: #fefaf5;
  /* margin: 165px 0 0; */
  margin: 193px 0 0;
  padding: 64px 0 136px;
  position: relative;

  @media only screen and (min-width: 1024px) {
    margin: 225px 0 0;
  }

  &::before {
    background: url(${dogs}) no-repeat 0 0;
    content: '';
    height: 133px;
    left: calc(50% - 128px);
    position: absolute;
    bottom: calc(100% - 1px);
    width: 256px;
  }

  .contact {
    display: grid;
    grid-gap: 26px;

    @media only screen and (min-width: 768px) {
      grid-template-columns: repeat(2, 1fr);
    }

    &__item {
      background: #fefaf5;
      color: #242323;
      padding: 32px 24px;

      .gatsby-image-wrapper {
        border-radius: 100%;
      }

      &--submitted {
        background: none;
        text-align: center;

        p {
          color: #FEFAF5;
        }
      }
    }

    .msg-success {
      img {
        margin: 0 0 26px;
      }
      &__subtitle {
        font-size: 1.4rem;
        margin: 0 0 4px;
      }
      &__title {
        font-size: 2.5rem;
        margin: 0 0 4px;
      }
      &__desc {
        font-size: 1.8rem;
        max-width: 300px;
        margin: 0 auto;
      }
    }

    &__heading {
      text-align: center;

      @media only screen and (min-width: 768px) {
        align-items: center;
        display: grid;
        grid-gap: 20px;
        grid-template-columns: 1fr 2fr;
        text-align: left;

        h3 {
          margin-top: 0;
        }
      }
    }

    @media only screen and (min-width: 768px) {
      .btn {
        max-width: none;
        width: auto;
      }
    }

    h4 {
      margin: 28px 0 20px;

      @media only screen and (min-width: 768px) {
        margin-top: 8px;
      }

      span {
        display: block;
      }
    }

    form {
      margin: 32px 0 0;

      .field {
        margin: 0 0 24px;

        ul {
          list-style: none;
          padding: 0 0 0 5px;

          input {
            margin-right: 10px;
            position: relative;
            top: 1px;
          }
        }
      }

      fieldset {
        border: 0;
        padding: 0;
      }

      label {
        display: block;
        font-size: 1.4rem;
        margin: 0 0 8px;
        text-align: left;
        width: 100%;
      }

      input[type="text"],
      input[type="email"] {
        border: 1px solid #BDB5AA;
        border-radius: 4px;
        font-size: 1.4rem;
        padding: 12px;
        width: 100%;

        &:hover,
        &:focus {
          border-color: #78569D;
          outline: none;
        }
      }

      .btn {
        max-width: 274px;
        width: 100%;
      }
    }

    .agree {
      margin-top: 25px;
      padding: 0 0 0 28px;
      position: relative;

      input[type="checkbox"] {
        position: absolute;
        left: 0;
        top: 2px;
      }

      a {
        color: #503969;
      }
    }
  }
`;

const IndexPage = ({ data }) => {
  const [showForm, setShowForm] = useState(false);
  const [showFormBusiness, setShowFormBusiness] = useState(false);

  const [formSubmitted, setFormSubmitted] = useState(false);
  const [formBusinessSubmitted, setFormBusinessSubmitted] = useState(false);

  const linkToEcommerce = 'https://femi-shop.com/kategoria-produktu/wszystko/pies-i-kot/';

  const handleClick = () => {
    setShowForm(true);
  };

  const handleBusinessClick = () => {
    setShowFormBusiness(true);
  };

  const handleFormSubmitSuccess = () => {
    setFormSubmitted(true);
  };

  const handleFormBusinessSubmitSuccess = () => {
    setFormBusinessSubmitted(true);
  };


  return (
    <>
        <HeaderComponent />
        <Main>
        <Hero>
            <GatsbyImage
            image={getImage(data.hero_mobile)}
            alt="Natural care for happy pets"
            loading="eager"
            className="only-mobile"
            />
            <GatsbyImage
            image={getImage(data.hero_desktop)}
            alt="Natural care for happy pets"
            loading="eager"
            className="only-desktop"
            />
        </Hero>

        <Mission id="misja">
            <div className="row">
            <h2>{messages[language].mission}</h2>
            <p className="lead">{messages[language].missionText1}</p>
            <p>{messages[language].missionText2}</p>
            </div>
        </Mission>

        <Products id="produkty">
            <div className="row">
            <h2>{messages[language].products}</h2>

            <div className="columns">
                <div className="col col--6 col--invert">
                <GatsbyImage
                    image={getImage(data.care)}
                    alt="kosmetyki dla psów"
                    loading="eager"
                />
                </div>

                <div className="col col--6">
                <h3>{messages[language].care}</h3>

                <p className="lead">{messages[language].careText}</p>

                <ul className="list-check">
                    <li>
                    <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd" d="M9.12432 15.2491L4.23039 10.5568L5.38386 9.35379L9.01146 12.832L14.5625 6.41429L15.823 7.50461L9.12432 15.2491ZM10 18.8333C14.6024 18.8333 18.3333 15.1024 18.3333 10.5C18.3333 5.89763 14.6024 2.16667 10 2.16667C5.39763 2.16667 1.66667 5.89763 1.66667 10.5C1.66667 15.1024 5.39763 18.8333 10 18.8333ZM10 20.5C15.5228 20.5 20 16.0228 20 10.5C20 4.97715 15.5228 0.5 10 0.5C4.47715 0.5 0 4.97715 0 10.5C0 16.0228 4.47715 20.5 10 20.5Z" fill="#494541" />
                    </svg>
                    {messages[language].careBullet1}
                    </li>
                    <li>
                    <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd" d="M9.12432 15.2491L4.23039 10.5568L5.38386 9.35379L9.01146 12.832L14.5625 6.41429L15.823 7.50461L9.12432 15.2491ZM10 18.8333C14.6024 18.8333 18.3333 15.1024 18.3333 10.5C18.3333 5.89763 14.6024 2.16667 10 2.16667C5.39763 2.16667 1.66667 5.89763 1.66667 10.5C1.66667 15.1024 5.39763 18.8333 10 18.8333ZM10 20.5C15.5228 20.5 20 16.0228 20 10.5C20 4.97715 15.5228 0.5 10 0.5C4.47715 0.5 0 4.97715 0 10.5C0 16.0228 4.47715 20.5 10 20.5Z" fill="#494541" />
                    </svg>
                    {messages[language].careBullet2}
                    </li>
                    <li>
                    <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd" d="M9.12432 15.2491L4.23039 10.5568L5.38386 9.35379L9.01146 12.832L14.5625 6.41429L15.823 7.50461L9.12432 15.2491ZM10 18.8333C14.6024 18.8333 18.3333 15.1024 18.3333 10.5C18.3333 5.89763 14.6024 2.16667 10 2.16667C5.39763 2.16667 1.66667 5.89763 1.66667 10.5C1.66667 15.1024 5.39763 18.8333 10 18.8333ZM10 20.5C15.5228 20.5 20 16.0228 20 10.5C20 4.97715 15.5228 0.5 10 0.5C4.47715 0.5 0 4.97715 0 10.5C0 16.0228 4.47715 20.5 10 20.5Z" fill="#494541" />
                    </svg>
                    {messages[language].careBullet3}
                    </li>
                    <li>
                    <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd" d="M9.12432 15.2491L4.23039 10.5568L5.38386 9.35379L9.01146 12.832L14.5625 6.41429L15.823 7.50461L9.12432 15.2491ZM10 18.8333C14.6024 18.8333 18.3333 15.1024 18.3333 10.5C18.3333 5.89763 14.6024 2.16667 10 2.16667C5.39763 2.16667 1.66667 5.89763 1.66667 10.5C1.66667 15.1024 5.39763 18.8333 10 18.8333ZM10 20.5C15.5228 20.5 20 16.0228 20 10.5C20 4.97715 15.5228 0.5 10 0.5C4.47715 0.5 0 4.97715 0 10.5C0 16.0228 4.47715 20.5 10 20.5Z" fill="#494541" />
                    </svg>
                    {messages[language].careBullet4}
                    </li>
                </ul>

                {/* <AnchorLink to="/#kontakt" title="Przejdź do kontaktu" className="btn">{messages[language].buttonCtaText" /></AnchorLink> */}
                <a href={linkToEcommerce} title="Przejdź do sklepu" className="btn" rel="nofollow">
                    {messages[language].buttonCtaText}
                </a>
                </div>
            </div>

            <div className="columns">
                <div className="col col--6">
                <GatsbyImage
                    image={getImage(data.supplements)}
                    alt="suplementy dla psów i kotów"
                    loading="eager"
                />
                </div>

                <div className="col col--6">
                <h3>{messages[language].supplements}</h3>

                <p className="lead">{messages[language].supplementsText}</p>

                <ul className="list-check">
                    <li>
                    <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd" d="M9.12432 15.2491L4.23039 10.5568L5.38386 9.35379L9.01146 12.832L14.5625 6.41429L15.823 7.50461L9.12432 15.2491ZM10 18.8333C14.6024 18.8333 18.3333 15.1024 18.3333 10.5C18.3333 5.89763 14.6024 2.16667 10 2.16667C5.39763 2.16667 1.66667 5.89763 1.66667 10.5C1.66667 15.1024 5.39763 18.8333 10 18.8333ZM10 20.5C15.5228 20.5 20 16.0228 20 10.5C20 4.97715 15.5228 0.5 10 0.5C4.47715 0.5 0 4.97715 0 10.5C0 16.0228 4.47715 20.5 10 20.5Z" fill="#494541" />
                    </svg>
                    {messages[language].supplementsBullet1}
                    </li>
                    <li>
                    <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd" d="M9.12432 15.2491L4.23039 10.5568L5.38386 9.35379L9.01146 12.832L14.5625 6.41429L15.823 7.50461L9.12432 15.2491ZM10 18.8333C14.6024 18.8333 18.3333 15.1024 18.3333 10.5C18.3333 5.89763 14.6024 2.16667 10 2.16667C5.39763 2.16667 1.66667 5.89763 1.66667 10.5C1.66667 15.1024 5.39763 18.8333 10 18.8333ZM10 20.5C15.5228 20.5 20 16.0228 20 10.5C20 4.97715 15.5228 0.5 10 0.5C4.47715 0.5 0 4.97715 0 10.5C0 16.0228 4.47715 20.5 10 20.5Z" fill="#494541" />
                    </svg>
                    {messages[language].supplementsBullet2}
                    </li>
                    <li>
                    <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd" d="M9.12432 15.2491L4.23039 10.5568L5.38386 9.35379L9.01146 12.832L14.5625 6.41429L15.823 7.50461L9.12432 15.2491ZM10 18.8333C14.6024 18.8333 18.3333 15.1024 18.3333 10.5C18.3333 5.89763 14.6024 2.16667 10 2.16667C5.39763 2.16667 1.66667 5.89763 1.66667 10.5C1.66667 15.1024 5.39763 18.8333 10 18.8333ZM10 20.5C15.5228 20.5 20 16.0228 20 10.5C20 4.97715 15.5228 0.5 10 0.5C4.47715 0.5 0 4.97715 0 10.5C0 16.0228 4.47715 20.5 10 20.5Z" fill="#494541" />
                    </svg>
                    {messages[language].supplementsBullet3}
                    </li>
                    <li>
                    <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd" d="M9.12432 15.2491L4.23039 10.5568L5.38386 9.35379L9.01146 12.832L14.5625 6.41429L15.823 7.50461L9.12432 15.2491ZM10 18.8333C14.6024 18.8333 18.3333 15.1024 18.3333 10.5C18.3333 5.89763 14.6024 2.16667 10 2.16667C5.39763 2.16667 1.66667 5.89763 1.66667 10.5C1.66667 15.1024 5.39763 18.8333 10 18.8333ZM10 20.5C15.5228 20.5 20 16.0228 20 10.5C20 4.97715 15.5228 0.5 10 0.5C4.47715 0.5 0 4.97715 0 10.5C0 16.0228 4.47715 20.5 10 20.5Z" fill="#494541" />
                    </svg>
                    {messages[language].supplementsBullet4}
                    </li>
                </ul>

                <a href={linkToEcommerce} title="Przejdź do sklepu" className="btn" rel="nofollow">
                    {messages[language].buttonCtaText}
                </a>
                </div>
            </div>
            </div>

            <div style={{ maxWidth: '1248px', margin: '0 auto' }}>
            <div className="ingredients">
                <GatsbyImage
                image={getImage(data.ingredients_mobile)}
                alt="min. 95% naturalnych składników"
                loading="eager"
                className="only-mobile"
                />

                <GatsbyImage
                image={getImage(data.ingredients_desktop)}
                alt="na bazie ekstraktów z nasion owsa"
                loading="eager"
                className="only-desktop"
                />
            </div>
            </div>
        </Products>

        <AboutUs id="o-nas">
            <div className="about-us__desc">
            <div className="about-us__content">
                <h2>{messages[language].whoWeAre}</h2>
                <ul className="list-experience">
                <li>
                    <GatsbyImage
                    image={getImage(data.paw)}
                    alt="psia łapa  "
                    loading="eager"
                    />
                </li>
                <li>
                    <p className="outline">{messages[language].whoWeAreLabel}</p>
                </li>
                </ul>
                <p className="lead">
                {messages[language].whoWeAreText1}
                </p>
                <p>{messages[language].whoWeAreText2}</p>
                <p>{messages[language].whoWeAreText3}</p>
            </div>
            </div>

            <div className="about-us__image">
            <GatsbyImage
                image={getImage(data.fair)}
                alt="fair from nose to tail"
                loading="eager"
            />
            </div>
        </AboutUs>

        <Benefits>
            <div className="row">
            <ul className="list-benefits">
                <li>
                <div className="list-benefits__desc">
                    <svg width="30" height="28" viewBox="0 0 30 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd" d="M14.9998 27.3333L27.4056 14.4025C30.4203 11.2602 30.4203 6.16562 27.4056 3.02336C24.5116 0.00688297 19.8915 -0.113861 16.8593 2.66112C16.7329 2.77683 16.6092 2.89758 16.4885 3.02336L15 4.57494L13.5113 3.02332C13.3907 2.89755 13.267 2.77682 13.1406 2.66112C10.1084 -0.113905 5.48826 0.00682877 2.59425 3.02332C-0.420417 6.16558 -0.420414 11.2602 2.59426 14.4025L14.9998 27.3333ZM4.51855 12.5563C2.49373 10.4458 2.49371 6.97998 4.51853 4.86947C6.4836 2.82123 9.622 2.82123 11.5871 4.86947L15 8.4268L18.4128 4.86951C20.3779 2.82128 23.5163 2.82128 25.4813 4.86951C27.5062 6.98002 27.5062 10.4458 25.4813 12.5563L14.9999 23.4814L4.51855 12.5563Z" fill="#D31919" />
                    </svg>
                    <p className="title">{messages[language].benefits1}</p>
                    <p style={{ marginBottom: '40px' }}>{messages[language].benefitsText1}</p>
                </div>

                <div className="list-benefits__desc">
                    <svg width="30" height="32" viewBox="0 0 30 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd" d="M14.9998 4.67681C13.9264 2.34177 12.0572 -0.015625 8.88477 -0.015625C6.30744 -0.015625 4.2181 2.05579 4.2181 4.611C4.2181 5.31493 4.37666 5.98213 4.66031 6.57943H3.0153C1.54254 6.57943 0.348633 7.77333 0.348633 9.24609V15.9128H3V31.9411H27V15.9128H29.6517V9.24609C29.6517 7.77333 28.4578 6.57943 26.985 6.57943H25.3394C25.623 5.98213 25.7816 5.31493 25.7816 4.611C25.7816 2.05579 23.6922 -0.015625 21.1149 -0.015625C17.9425 -0.015625 16.0733 2.34177 14.9998 4.67681ZM12.9027 6.57096C12.7981 6.29479 12.6816 6.0149 12.5523 5.73753C11.6135 3.72327 10.4337 2.65104 8.88477 2.65104C7.75849 2.65104 6.88477 3.55016 6.88477 4.611C6.88477 5.67184 7.75849 6.57096 8.88477 6.57096H12.9027ZM17.097 6.57096C17.2016 6.29479 17.3181 6.0149 17.4473 5.73753C18.3861 3.72327 19.566 2.65104 21.1149 2.65104C22.2412 2.65104 23.1149 3.55016 23.1149 4.611C23.1149 5.67184 22.2412 6.57096 21.1149 6.57096H17.097ZM3.0153 9.24609H13.6667V13.2461H3.0153V9.24609ZM16.3333 13.2461V9.24609H26.985V13.2461H16.3333ZM5.66667 15.9411H13.6667V29.2744H5.66667V15.9411ZM16.3333 15.9411V29.2744H24.3333V15.9411H16.3333Z" fill="#78569D" />
                    </svg>
                    <p className="title">{messages[language].benefits2}</p>
                    <p>{messages[language].benefitsText2}</p>
                </div>
                </li>

                <li>
                <div className="list-benefits__desc">
                    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd" d="M14.6667 3.33333C14.6667 3.70152 14.3682 4 14 4C13.6318 4 13.3333 3.70152 13.3333 3.33333C13.3333 2.96514 13.6318 2.66667 14 2.66667C14.3682 2.66667 14.6667 2.96514 14.6667 3.33333ZM17.3333 3.33333C17.3333 5.17428 15.841 6.66667 14 6.66667C12.1591 6.66667 10.6667 5.17428 10.6667 3.33333C10.6667 1.49238 12.1591 0 14 0C15.841 0 17.3333 1.49238 17.3333 3.33333ZM6.81991 29.3333L14.6667 19.3465V13.3333H17.3333V19.3465L25.1801 29.3333H6.81991ZM20 13.3333V18.4242L28.9718 29.8429C29.6592 30.7177 29.036 32 27.9234 32H4.07662C2.96404 32 2.34082 30.7177 3.02819 29.8429L12 18.4242V13.3333H9.33333V10.6667H12H20H22.6667V13.3333H20ZM21.3333 7.33333C21.3333 8.4379 20.4379 9.33333 19.3333 9.33333C18.2288 9.33333 17.3333 8.4379 17.3333 7.33333C17.3333 6.22876 18.2288 5.33333 19.3333 5.33333C20.4379 5.33333 21.3333 6.22876 21.3333 7.33333Z" fill="#699F8C" />
                    </svg>
                    <p className="title">{messages[language].benefits3}</p>
                    <p>{messages[language].benefitsText3}</p>
                </div>
                </li>
            </ul>
            </div>
        </Benefits>

        <Contact id="kontakt" className="contact-us">
            <div className="row">
            <div className="heading">
                <h2>
                <span>{messages[language].contactHeader}</span>
                </h2>
                <div className="heading__desc">
                <p>{messages[language].contactText}</p>
                </div>
            </div>

            <div className="contact">
                <div className="contact__item-wrap">
                <div className={!formSubmitted ? 'contact__item' : 'contact__item contact__item--submitted'}>
                    {formSubmitted ? null : (
                    <div className="contact__heading">
                        <div className="contact__image">
                        <GatsbyImage
                            image={getImage(data.buldog)}
                            alt="buldog"
                            loading="eager"
                        />
                        </div>
                        <div className="contact__desc">
                        <h4>
                            <span>{messages[language].contactLabelIndividual1}</span>
                            <span>{messages[language].contactLabelIndividual2}</span>
                        </h4>
                        {!showForm ? (<button className="btn" onClick={handleClick} aria-label="Contact Us">{messages[language].contactCtaButton}</button>) : ('')}
                        </div>
                    </div>
                    )}

                    <div>
                    {showForm ? (
                        <ContactForm onSubmitSuccess={handleFormSubmitSuccess} />
                    ) : (
                        ''
                    )}
                    </div>
                </div>
                </div>

                <div className="contact__item-wrap">
                <div className={!formBusinessSubmitted ? 'contact__item' : 'contact__item contact__item--submitted'}>
                    {formBusinessSubmitted ? null : (
                    <div className="contact__heading">
                        <div className="contact__image">
                        <GatsbyImage
                            image={getImage(data.dog)}
                            alt="dog"
                            loading="eager"
                        />
                        </div>
                        <div className="contact__desc">
                        <h4>
                            <span>{messages[language].contactLabelBusiness1}</span>
                            <span>{messages[language].contactLabelBusiness2}</span>
                        </h4>
                        {!showFormBusiness ? (<button className="btn" onClick={handleBusinessClick} aria-label="Contact Us">{messages[language].contactCtaButton}</button>) : ('')}
                        </div>
                    </div>
                    )}

                    <div>
                    {showFormBusiness ? (
                        <ContactFormBusiness onBusinessSubmitSuccess={handleFormBusinessSubmitSuccess} />
                    ) : (
                        ''
                    )}
                    </div>
                </div>
                </div>
            </div>
            </div>
        </Contact>
        </Main>
    </>
  );
};

export const query = graphql`
  query {
    hero_mobile: file(relativePath: {regex: "/home\/hero\/mobile.png/"}) {
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          placeholder: BLURRED
          width: 2000
        )
      }
    },
    hero_desktop: file(relativePath: {regex: "/home\/hero\/desktop.png/"}) {
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          placeholder: BLURRED
          width: 2500
        )
      }
    },
    care: file(relativePath: {regex: "/home\/care.png/"}) {
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          placeholder: DOMINANT_COLOR
          width: 579
        )
      }
    },
    ingredients_mobile: file(relativePath: {regex: "/home\/ingredients-mobile.png/"}) {
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          placeholder: DOMINANT_COLOR
          width: 312
        )
      }
    },
    ingredients_desktop: file(relativePath: {regex: "/home\/ingredients-desktop.png/"}) {
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          placeholder: DOMINANT_COLOR
          width: 1248
        )
      }
    },
    supplements: file(relativePath: {regex: "/home\/supplements.png/"}) {
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          placeholder: DOMINANT_COLOR
          width: 458
        )
      }
    },
    paw: file(relativePath: {regex: "/home\/paw.jpeg/"}) {
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          placeholder: DOMINANT_COLOR
          width: 208
        )
      }
    },
    fair: file(relativePath: {regex: "/home\/fair.jpeg/"}) {
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          placeholder: DOMINANT_COLOR
          width: 702
        )
      }
    },
    buldog: file(relativePath: {regex: "/home\/buldog.png/"}) {
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          placeholder: DOMINANT_COLOR
          width: 136
        )
      }
    },
    dog: file(relativePath: {regex: "/home\/dog.png/"}) {
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          placeholder: DOMINANT_COLOR
          width: 136
        )
      }
    },
    heart: file(relativePath: {regex: "/home\/heart.png/"}) {
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          placeholder: DOMINANT_COLOR
          width: 126
        )
      }
    },
  }`;

export default IndexPage;
